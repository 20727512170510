import { graphql, useStaticQuery } from 'gatsby'
import useMediaQuery from '../../hooks/use-media-query'
const queryJiriLunak = graphql`
  {
    bigdesktop: file(relativePath: { eq: "jiriLunak.png" }) {
      childImageSharp {
        fixed(width: 148) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    smalldesktop: file(relativePath: { eq: "jiriLunak.png" }) {
      childImageSharp {
        fixed(width: 148) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    tablet: file(relativePath: { eq: "jiriLunak.png" }) {
      childImageSharp {
        fixed(width: 148) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    mobile: file(relativePath: { eq: "jiriLunak.png" }) {
      childImageSharp {
        fixed(width: 148) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
export const JiriLunakImage = () => {
  const { bigdesktop, smalldesktop, tablet, mobile } = useStaticQuery(queryJiriLunak)
  const isLargeDesktop = useMediaQuery('(min-width: 1920px)')
  const isDesktop = useMediaQuery('(min-width: 1366px)')
  const isTablet = useMediaQuery('(min-width: 768px)')

  if (isLargeDesktop) {
    return bigdesktop.childImageSharp.fixed
  }

  if (isDesktop) {
    return smalldesktop.childImageSharp.fixed
  }

  if (isTablet) {
    return tablet.childImageSharp.fixed
  }

  return mobile.childImageSharp.fixed
}
