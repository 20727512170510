import { IntlFormatters, injectIntl } from 'gatsby-plugin-intl'
import Footer from '../components/footer'
import { IvoImage } from '../components/images/ivo'
import { OndrejStepanekImage } from '../components/images/ondrejStepanek'
import { PetrNovotnyImage } from '../components/images/petrNovotny'
import { S9YImage } from '../components/images/s9y'
import Layout from '../components/layout/layout'
import Text from '../components/sections/components/text'
import { SectionOurTeam } from '../components/sections/section-our.team'
import { SectionWithForm } from '../components/sections/section-with-form'
import React from 'react'
import { ZbynekBohacekImage } from '../components/images/zbynekBohacek'
import { JiriLunakImage } from '../components/images/jiriLunak'
import { PetrVozkaImage } from '../components/images/petrVozka'
import { TomasNovotnyImage } from '../components/images/tomasNovotny'

const Contact = ({ intl }: IntlFormatters) => {
  const ivo = IvoImage()
  const petrNovotny = PetrNovotnyImage()
  const s9y = S9YImage()
  const ondrejStepanek = OndrejStepanekImage()
  const zbynekBohacek = ZbynekBohacekImage()
  const jiriLunak = JiriLunakImage()
  const petrVozka = PetrVozkaImage()
  const tomasNovotny = TomasNovotnyImage()
  return (
    <Layout>
      <SectionWithForm
        formEmail={intl.formatMessage({ id: 'form.email' })}
        formMessage={intl.formatMessage({ id: 'form.textMessage' })}
        formName={intl.formatMessage({ id: 'form.name' })}
        heading={intl.formatMessage({ id: 'contact.heading' })}
        text={<Text>{intl.formatMessage({ id: 'contact.text' })}</Text>}
        buttonText={intl.formatMessage({ id: 'form.send' })}
        emailPlaceholder={intl.formatMessage({ id: 'form.emailPlaceholder' })}
        messagePlaceholder={intl.formatMessage({ id: 'form.messagePlaceholder' })}
        namePlaceholder={intl.formatMessage({ id: 'form.namePlaceholder' })}
      />
      <SectionOurTeam
        heading={intl.formatMessage({ id: 'contact.heading2' })}
        members={[
          {
            name: intl.formatMessage({ id: 'contact.name1' }),
            description: intl.formatMessage({ id: 'contact.description1' }),
            phone: intl.formatMessage({ id: 'contact.phone' }),
            email: intl.formatMessage({ id: 'contact.email' }),
            image: ivo,
          },
          {
            name: intl.formatMessage({ id: 'contact.name2' }),
            description: intl.formatMessage({ id: 'contact.description2' }),
            image: petrNovotny,
          },
          {
            name: intl.formatMessage({ id: 'contact.name4' }),
            description: intl.formatMessage({ id: 'contact.description4' }),
            image: ondrejStepanek,
            phone: intl.formatMessage({ id: 'contact.phone2' }),
            email: intl.formatMessage({ id: 'contact.email2' }),
          },
          {
            name: intl.formatMessage({ id: 'contact.name6' }),
            description: intl.formatMessage({ id: 'contact.description6' }),
            image: zbynekBohacek,
            phone: intl.formatMessage({ id: 'contact.phone4' }),
            email: intl.formatMessage({ id: 'contact.email4' }),
          },
          {
            name: intl.formatMessage({ id: 'contact.name7' }),
            description: intl.formatMessage({ id: 'contact.description7' }),
            image: jiriLunak,
            phone: intl.formatMessage({ id: 'contact.phone5' }),
            email: intl.formatMessage({ id: 'contact.email5' }),
          },
          {
            name: intl.formatMessage({ id: 'contact.name8' }),
            description: intl.formatMessage({ id: 'contact.description8' }),
            image: petrVozka,
            phone: intl.formatMessage({ id: 'contact.phone6' }),
            email: intl.formatMessage({ id: 'contact.email6' }),
          },
          {
            name: intl.formatMessage({ id: 'contact.name10' }),
            description: intl.formatMessage({ id: 'contact.description10' }),
            image: tomasNovotny,
            phone: intl.formatMessage({ id: 'contact.phone8' }),
            email: intl.formatMessage({ id: 'contact.email8' }),
          },
          {
            name: intl.formatMessage({ id: 'contact.name3' }),
            description: intl.formatMessage({ id: 'contact.description3' }),
            image: s9y,
          },
        ]}
        companyInfo={intl.formatMessage({ id: 'contact.companyInfo' })}
        companyName={intl.formatMessage({ id: 'contact.companyName' })}
        emailLabrulez={intl.formatMessage({ id: 'contact.emailLabrulez' })}
        address={intl.formatMessage({ id: 'contact.address' })}
      />
      <Footer />
    </Layout>
  )
}
export default injectIntl(Contact)
